import * as React from "react"
import Menu from "../components/menu"
import Footer from "../components/footer"
import Seo from "../components/seo"
import clsx from "clsx"
import { Link } from "gatsby"
import { title } from "../styles"

// markup
const NotFoundPage = (props) => {
  return (
    <div> 
      <Seo>Error 404</Seo>
      <Menu />
      <div className="max-w-screen-xl mx-5 xl:mx-auto">
        <div className="mt-12 md:pt-32 text-center pb-12">
          <h3 className={ clsx("text-xs", "text-dark-lavender") }>Error 404</h3>
          <h1 className={ clsx(title, "text-center", "my-4") }>Page Not Found</h1>
          <div className="font-sans text-sm font-normal text-battleship-grey">
            <p>Sorry, we couldn't find the page you're looking for.</p>
          </div>
          <Link to="/" className={clsx("mt-4", "text-dark-lavender", "text-xs")}>Go back home →</Link>
        </div>
      </div>
      <Footer/>
    </div>
     
  
  )
}


export default NotFoundPage